<template>
    <div>
        <v-select
            v-model="newValue" 
            @change="emitValue"
            :class="errors && errors.length ? 'hasError' : ''"
            outlined
            :error="errors && errors.length > 0"
            :menu-props="{bottom: true, offsetY: true}"
            :clearable="clearable"
            :hide-details="true"
            :items="options"
            :returnObject="returnObject"
            :item-text="itemText"
            :item-value="itemValue"
            :disabled="disabled || false"
            :label="required ? label + '*' : label">
            <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
        </v-select>
        <ErrorMessages :errors="errors"></ErrorMessages>
    </div> 
</template>

<script> 

export default {
    name: 'Dropdown',
    props: {
        value: [String, Number, Boolean, Object],
        rules: String,
        options: Array,
        label: String,
        clearable: Boolean,
        required: Boolean,
        errors: Array,
        disabled: Boolean,
        returnObject: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: "text"
        },
        itemValue: {
            type: String,
            default: "value"
        }
    },
    emits: [
        'change'
    ],
    data() {
        return {
            newValue: null
        }
    },
    methods: {
        emitValue() {
            this.$emit('change', this.newValue)       
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.newValue = newVal;
            }
        }
    },
}

</script>